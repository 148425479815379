import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostThumbnail from '../components/posts/PostThumbnail';

const PostList = ({ posts }) => (
  <div className="list">
    { posts.map((post, key) => {
      if (post) {
        return (
          <div key={ `item-${key * key}` } className="list__item list__item--small">
            <Link to={ post.fields.slug }>
              <PostThumbnail thumbnail={ post.data.Thumbnail } />
            </Link>
          </div>
        );
      }
      return (null);
    }) }
  </div>
);

const NotFoundPage = ({ data }) => (
  <Layout pageClass="page--full">
    <SEO title="404: Not found" />

    <h1 className="page__title page__title--tight">
      Oh dear, this link is not working.
    </h1>
    <div className="page-not-found">
      <div className="page-not-found__description">
        Apparently the page you are looking for is in quarantine ;-)
      </div>
      <div className="page-not-found__direct">
        Go
        { ' ' }
        <Link to="/">Home</Link>
        { ' ' }
        or continue to...
      </div>
      <ul className="page-not-found__menu">
        { [ 'Look', 'Listen', 'Learn', 'Grow' ].map((type, key) => (
          <li key={ `item-${key * key}` } className="page-not-found__menu__item">
            <Link to={ `/${type.toLowerCase()}` }>
              { type }
            </Link>
          </li>
        )) }
      </ul>
      <PostList posts={ Object.values(data) } />
      <div className="page-not-found__description">
        And while you are here, how about signing up for updates or check out our social pages...
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
  fragment postFields on Airtable {
    fields {
      slug
    }
    data {
      Thumbnail {
        localFiles {
          extension
          url
          childImageSharp {
            fluid(maxHeight: 237) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }

  query {
    post1: airtable(fields: {slug: {eq: "/posts/kaust-through-my-eyes"}}) {
      ...postFields
    }
    post2: airtable(fields: {slug: {eq: "/posts/thefrontstepsproject"}}) {
      ...postFields
    }
    post3: airtable(fields: {slug: {eq: "/posts/dont-rush"}}) {
      ...postFields
    }
    post4: airtable(fields: {slug: {eq: "/posts/le-depart"}}) {
      ...postFields
    }
    post5: airtable(fields: {slug: {eq: "/posts/keeping-contact-with-photography-2"}}) {
      ...postFields
    }
    post6: airtable(fields: {slug: {eq: "/posts/april-11-2020"}}) {
      ...postFields
    }
  }
`;
